<template>
  <div>
    <div class="flex flex-col justify-evenly">
      <div class="flex justify-start w-full text-xl mb-2">
        Manual Product Price Rule Update
      </div>
      <div class="flex justify-start items-center w-full pt-2 h-20">
        <BaseInput
          class="md:w-1/2 mb--4"
          :value="keyword"
          type="text"
          :disabled="!!activeProductId"
          label="Search by Name, Inventory ID, or Ecwid ID"
          @input="handleSearch"
          :id="`search-product`"
        />
      </div>
      <div style="min-height: 400px">
        <!-- UPDATE PRODUCT  -->
        <div
          v-if="activeProductId && activeProduct"
          class="flex flex-col items-start justify-between mt-8"
        >
          <div class="text-2xl">{{ activeProduct.name }}</div>
          <div
            :key="`update-product-price_rule`"
            class="flex justify-between w-full pt-2"
          >
            <div class="flex flex-col items-start w-1/6">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="amount"
              >
                Amount
              </label>
              <div class="mt-2">
                {{ "$" + activeproduct.cost.toFixed(2) }}
              </div>
            </div>
            <BaseInput
              class="w-1/6 ml-4"
              :value="temp_factor"
              type="text"
              label="Factor"
              @input="handleFactorChange($event)"
              :id="`active_product-factor`"
            />
            <div class="flex flex-col items-start w-1/6 ml-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="password"
              >
                New Price
              </label>
              <div class="mt-2">
                {{ calc.new_price }}
              </div>
            </div>
            <div class="flex flex-col items-start w-1/6 ml-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="password"
              >
                Profit
              </label>
              <div class="mt-2">
                {{ calc.gross_profit }}
              </div>
            </div>
            <div class="flex flex-col items-start w-1/6 ml-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="password"
              >
                Margin
              </label>
              <div class="mt-2">
                {{ calc.gross_margin }}
              </div>
            </div>
          </div>
          <div class="flex mt-8">
            <BaseButton
              @clicked="dispatchUpdateProductPriceRule"
              :disabled="sending || !!hasSaveError"
              :title="hasSaveError ? hasSaveError : 'Update Price Rule'"
              >Update Ecwid Price</BaseButton
            >
            <div
              @click="setActiveProduct(null)"
              class="mx-4 my-2 hover:underline cursor-pointer"
            >
              Close
            </div>
            <div v-if="success" class="ml-4 my-2 italic text-green-700">
              {{ success }}
            </div>
            <div v-if="error" class="ml-4 my-2 italic text-red-700">
              {{ error }}
            </div>
          </div>
        </div>
        <!-- PRODUCTS  -->
        <div
          v-show="!activeProductId"
          v-for="(product, product_index) in activeProductList"
          :key="`price-rule-${product.id}`"
          class="flex justify-between w-full px-4 pt-2 cursor-pointer hover:shadow"
          :class="{ 'bg-gray-200': product_index % 2 === 0 }"
          @click.stop="setActiveProduct(product.id)"
        >
          <div class="flex flex-col items-start">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="password"
            >
              Name (sku)
            </label>
            <div>{{ product.name }}</div>
            <div class="text-gray-600">{{ product.sku }}</div>
          </div>
          <BaseCurrency
            class="w-1/4"
            :value="product.cost_cents"
            type="number"
            label="Amount"
            :id="`Amount-${product.id}`"
            :disabled="!product_index"
          />
          <div class="flex flex-col items-start">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="password"
            >
              Factor
            </label>
            <div class="mt-2">x {{ product.price_rule.factor }}</div>
          </div>
          <BaseCurrency
            class="w-1/4"
            :value="product.price_cents"
            type="number"
            label="Price"
            :id="`PRice-${product.id}`"
            :disabled="!product_index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import router from "@/router";
import { debounce } from "@/helpers/utilities";
import BaseInput from "@/components/BaseInput.vue";
import BaseCurrency from "@/components/BaseCurrency.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "ProductList",
  components: {
    BaseButton,
    BaseCurrency,
    BaseInput,
    BaseForm,
  },
  data() {
    return {
      temp_factor: null,
      keyword: "",
      success: "",
      error: "",
      products: [],
      sending: false,
    };
  },
  created() {
    this.getProducts();
  },
  computed: {
    ...mapGetters("product", [
      "activeProductList",
      "activeProduct",
      "activeProductId",
    ]),
    calc() {
      if (!this.activeProductId) return {};
      let new_price = (this.activeproduct.cost * this.temp_factor).toFixed(2);
      let gross_profit = (Number(new_price) - this.activeproduct.cost).toFixed(
        2
      );
      let gross_margin = (
        (100 * (new_price - this.activeproduct.cost)) /
        new_price
      ).toFixed(2);
      return {
        new_price: "$" + new_price,
        gross_margin: gross_margin + "%",
        gross_profit: "$" + gross_profit,
      };
    },
    hasSaveError() {
      if (
        this.activeProductId &&
        this.temp_factor == this.activeProduct.price_rule.factor
      ) {
        return "No changes to Product";
      }
      if (!this.temp_factor || 0 > this.temp_factor || this.temp_factor > 100) {
        return "Factors must be a number between 0 - 100";
      }
      return null;
    },
  },
  methods: {
    ...mapActions("product", [
      "getProducts",
      "updateProductPriceRule",
      "setActiveProduct",
    ]),
    handleSearch(e) {
      const search = e.trim();
      this.keyword = search;
      debounce(this.getProducts({ keyword: search }), 1000);
    },
    clearMessages() {
      this.sending = false;
      setTimeout(() => {
        if (this.success) {
          this.setActiveProduct(null);
        }
        this.error = this.success = "";
      }, 2000);
    },
    dispatchUpdateProductPriceRule() {
      this.sending = true;
      this.updateProductPriceRule({
        factor: this.temp_factor,
        product_id: this.activeProductId,
      })
        .then(() => {
          this.success = "Updated Successfully";
          this.clearMessages();
        })
        .catch(() => {
          this.error = "Could Not Update Product Price Rule";
          this.clearMessages();
        });
    },
    handleFactorChange(value) {
      this.temp_factor = Number(value);
    },
  },
  watch: {
    activeProduct() {
      this.temp_factor =
        (this.activeProduct && this.activeProduct.price_rule.factor) || null;
    },
  },
};
</script>
