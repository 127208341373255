<template lang="html">
  <div class="flex flex-col" style="overflow: hidden; min-height: 500px">
    <BaseCard class="max-w-5xl mx-4 mt-4">
      <MetalPricing />
    </BaseCard>
    <BaseCard class="max-w-5xl mx-4 mt-4">
      <PriceRuleList />
    </BaseCard>
    <BaseCard class="max-w-5xl mx-4 mt-4">
      <ProductList />
    </BaseCard>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseInput from "@/components/BaseInput.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCard from "@/components/BaseCard.vue";
import PriceRuleList from "@/views/PriceRuleList.vue";
import ProductList from "@/views/ProductList.vue";
import MetalPricing from "@/views/MetalPricing.vue";

export default {
  name: "Home",
  components: {
    BaseForm,
    BaseButton,
    BaseInput,
    BaseCard,
    ProductList,
    PriceRuleList,
    MetalPricing,
  },
  data() {
    return {
      key: 0,
      localCompany: {},
    };
  },
  created() {},
  computed: {
    ...mapGetters("user", ["user"]),
  },
  methods: {},
};
</script>
