<template>
  <div>
    <div class="flex flex-col justify-evenly">
      <div class="w-full flex items-start flex-col">
        <div class="flex justify-start w-full text-xl mb-2">
          Setting Pricing
        </div>
        <!-- GOLD  -->
        <div class="flex flex-start w-full">
          <BaseCurrency
            :value="metalPriceLast.gold * 100"
            type="number"
            class="w-40"
            label="Last Gold Price / oz"
            :id="`last-gold-price`"
            :display="true"
          />
          <BaseCurrency
            :value="metalPrice.gold * 100"
            @input="metalPrice.gold = $event / 100"
            type="number"
            label="Current Gold / oz"
            :id="`current-gold-price`"
            class="ml-3 w-40"
          />
          <div
            class="ml-3 mt-8 pt-1 text-md whitespace-nowrap w-20"
            :class="{ 'text-red-700': getPriceDiff('gold') < 0 }"
          >
            {{ getPriceDiff("gold") }} %
          </div>
          <BaseCurrency
            :value="
              convert(
                convert(metalPrice.gold * 100, 'ounce', 'dwt'),
                'gold',
                '18k'
              )
            "
            type="number"
            :display="true"
            label="USD/18k gold dwt "
            :id="`current-gold-price`"
            class="ml-3 w-40"
          />
          <BaseCurrency
            :value="
              convert(
                convert(metalPrice.gold * 100, 'ounce', 'dwt'),
                'gold',
                '14k'
              )
            "
            type="number"
            label="USD / 14k gold dwt "
            :id="`current-gold-price`"
            class="ml-3 w-40"
            :display="true"
          />
        </div>
        <!-- Platinum  -->
        <div class="flex flex-start w-full">
          <BaseCurrency
            :value="metalPriceLast.platinum * 100"
            type="number"
            label="Last Platinum / oz"
            :id="`last-platinum-price`"
            :display="true"
            class="w-40"
          />
          <BaseCurrency
            :value="metalPrice.platinum * 100"
            @input="metalPrice.platinum = $event / 100"
            type="number"
            label="Current Platinum / oz"
            :id="`current-platinum-price`"
            class="ml-3 w-40"
          />
          <div
            class="ml-3 mt-8 pt-1 text-md whitespace-nowrap w-20"
            :class="{ 'text-red-700': getPriceDiff('platinum') < 0 }"
          >
            {{ getPriceDiff("platinum") }} %
          </div>
          <BaseCurrency
            :value="convert(metalPrice.platinum * 100, 'ounce', 'dwt')"
            type="number"
            :display="true"
            label="USD/pure platinum dwt "
            :id="`current-platinum-price`"
            class="ml-3 w-40"
          />
        </div>
      </div>
      <!-- <div
        v-for="(price_rule, price_rule_index) in price_rules_display"
        :key="`price-rule-${price_rule.id}`"
        class="flex justify-between w-full px-4 pt-2"
        :class="{ 'bg-gray-200': price_rule_index % 2 === 0 }"
      >
        <BaseCurrency
          class="w-1/4"
          :value="price_rule.min_threshold"
          @input="handleThresholdChange($event, price_rule.id)"
          type="number"
          label="Min Threshold"
          :id="`min_threshold-${price_rule.id}`"
          :disabled="!price_rule_index"
        />
        <BaseInput
          class="w-1/4"
          :value="price_rule.factor"
          type="text"
          label="Factor"
          @input="handleFactorChange($event, price_rule.id)"
          :id="`factor-${price_rule.id}`"
        />
      </div> -->

      <!-- <div class="flex justify-between">
        <div class="flex justify-start items-center mt-6">
          <BaseButton
            :disabled="!!hasSaveError"
            :title="hasSaveError ? hasSaveError : 'Save Price Rules'"
            >Save Assembly</BaseButton
          >
          <div v-if="success" class="ml-4 italic text-green-700">
            {{ success }}
          </div>
          <div v-if="error" class="ml-4 italic text-red-700">{{ error }}</div>
          <div v-if="changes" class="ml-4 italic text-orange-600">
            {{ changes }}
          </div>
        </div> -->
        <div class="mt-8 underline italic cursor-pointer flex flex-end w-full">
          <div @click="sendUpdatePricingRequest">Update Product Pricing</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import router from "@/router";
import { METAL_PRICE_BASE, CONVERSIONS } from "@/constants/vendors";
import BaseInput from "@/components/BaseInput.vue";
import BaseCurrency from "@/components/BaseCurrency.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSelect from "@/components/BaseSelect.vue";

export default {
  name: "MetalPricing",
  components: {
    BaseButton,
    BaseCurrency,
    BaseInput,
    BaseForm,
    BaseSelect,
  },
  data() {
    return {
      success: "",
      error: "",
      changes: "",
      metalPrice: { ...METAL_PRICE_BASE },
    };
  },
  created() {
    this.getPricing();
  },
  computed: {
    ...mapGetters("vendor", ["metalPriceCurrent", "metalPriceLast"]),
    hasSaveError() {
      return "";
    },
  },
  methods: {
    ...mapActions("vendor", ["getPricing"]),

    updateSettingPricing() {
        return ""
    },
    sendUpdatePricingRequest() {
      if (
        !confirm(
          "Are you sure you want to update pricing again? This will loop through all products and may take several hours do not double click!"
        )
      )
        return;
      this.updateSettingPricing();
    },
    getPriceDiff(type) {
      return !this.metalPrice[type]
        ? 0
        : ((this.metalPriceLast[type] - this.metalPrice[type]) /
            this.metalPrice[type]) *
            100;
    },
    convert(price, from, to) {
      return price * CONVERSIONS[from][to];
    },
  },
  watch: {
    metalPriceCurrent: {
      handler() {
        this.metalPrice = this.metalPriceCurrent;
      },
      immediate: true,
    },
  },
};
</script>
