<template lang="html">
    <button
        role="button"
        tabindex="0"
        class="bg-teal-600 hover:bg-teal-800 text-white py-2 px-4 rounded"
        :class="btn_class"
        @click="click()"
        @keyup.enter.prevent="click()"
        @keyup.space.prevent="click()"
        :title="title"
    >
        <slot></slot>
    </button>
</template>
<script>
export default {
    name: 'BaseButton',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: String,
    },
    computed: {
        btn_class() {
            return {
                'opacity-50 cursor-not-allowed shadow-none hover:shadow-none': this
                    .disabled,
                'hover:bg-gray-900': !this.disabled,
            }
        },
    },
    methods: {
        click() {
            if (this.disabled) return
            this.$emit('clicked')
        },
    },
}
</script>


