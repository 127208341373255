<template>
  <div>
    <div class="flex flex-col justify-evenly">
      <div class="w-full flex items-start flex-col">
        <div class="flex justify-start w-full text-xl mb-2">
          Price Rules by Cost
        </div>
        <div>
          <BaseSelect
            v-model="activeTarget"
            :options="targetOptions"
            label="Select Target"
            id="target"
            class="w-64"
          />
        </div>
      </div>
      <div
        v-for="(price_rule, price_rule_index) in price_rules_display"
        :key="`price-rule-${price_rule.id}`"
        class="flex justify-between w-full px-4 pt-2"
        :class="{ 'bg-gray-200': price_rule_index % 2 === 0 }"
      >
        <BaseCurrency
          class="w-1/4"
          :value="price_rule.min_threshold"
          @input="handleThresholdChange($event, price_rule.id)"
          type="number"
          label="Min Threshold"
          :id="`min_threshold-${price_rule.id}`"
          :disabled="!price_rule_index"
        />
        <BaseInput
          class="w-1/4"
          :value="price_rule.factor"
          type="text"
          label="Factor"
          @input="handleFactorChange($event, price_rule.id)"
          :id="`factor-${price_rule.id}`"
        />
        <div class="flex justify-end items-center w-1/12">
          <span
            v-if="price_rule_index"
            @click="handleDelete(price_rule.id)"
            class="cursor-pointer text-xs uppercase hover:underline"
            >delete</span
          >
        </div>
        <div class="flex justify-end items-center w-1/4">
          <span v-if="price_rule.threshold_range !== SORT_THRESHOLDS">
            {{ price_rule.threshold_range }}</span
          ><span
            v-if="price_rule.threshold_range === SORT_THRESHOLDS"
            @click="sortPriceRules"
            class="cursor-pointer text-xs uppercase hover:underline"
            >{{ SORT_THRESHOLDS }}</span
          >
        </div>
      </div>
      <div class="flex justify-center items-center w-full pt-2 h-20">
        <div
          @click="addNewPriceRule"
          class="bg-white border border-1 border-gray-800 text-gray-800 rounded py-2 px-4 cursor-pointer hover:shadow-md"
        >
          Add Next Price Rule
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex justify-start items-center mt-6">
          <BaseButton
            :disabled="!!hasSaveError"
            :title="hasSaveError ? hasSaveError : 'Save Price Rules'"
            @clicked="dispatchPriceRuleChanges"
            >Save Changes</BaseButton
          >
          <div v-if="success" class="ml-4 italic text-green-700">
            {{ success }}
          </div>
          <div v-if="error" class="ml-4 italic text-red-700">{{ error }}</div>
          <div v-if="changes" class="ml-4 italic text-orange-600">
            {{ changes }}
          </div>
        </div>
        <div class="mt-8 underline italic cursor-pointer">
          <div @click="sendUpdatePriceRules">Update Price Rules</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import router from "@/router";

import BaseInput from "@/components/BaseInput.vue";
import BaseCurrency from "@/components/BaseCurrency.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSelect from "@/components/BaseSelect.vue";

const PRICE_RULE_TYPE_DEFAULT = "default";
const PRICE_RULE_CRITERIA_COST = "cost";

const PRICE_RULE_TEMP = {
  factor: 1,
  criteria: PRICE_RULE_CRITERIA_COST,
  type_: PRICE_RULE_TYPE_DEFAULT,
};

const ACTION_TYPE_DELETE = "delete";
const ACTION_TYPE_CREATE = "create";
const ACTION_TYPE_UPDATE = "update";

const ACTIONS = [ACTION_TYPE_DELETE, ACTION_TYPE_CREATE, ACTION_TYPE_UPDATE];

const SORT_THRESHOLDS = "Sort by Thresholds";

export default {
  name: "PriceRuleList",
  components: {
    BaseButton,
    BaseCurrency,
    BaseInput,
    BaseForm,
    BaseSelect,
  },
  data() {
    return {
      SORT_THRESHOLDS,
      price_rules: [],
      success: "",
      error: "",
      changes: "",
      activeTarget: null,
    };
  },
  created() {
    this.getDefaultPriceRules().then(() => {
      this.updateLocalPriceRules();
    });
  },
  computed: {
    ...mapGetters("price_rule", [
      "priceRulesDefault",
      "priceRulesDefaultByTarget",
      "targetOptions",
    ]),
    price_rules_formatted() {
      return this.price_rules.map((pr, price_rule_index) => ({
        ...pr,
        ...{
          threshold_range: this.getThresholdRange(price_rule_index),
        },
      }));
    },
    price_rules_display() {
      return this.price_rules_formatted.filter(
        (pr) => pr.action_type !== ACTION_TYPE_DELETE
      );
    },
    hasSaveError() {
      if (!this.price_rules.some((pr) => pr.action_type)) {
        return "No changes to Price Rules";
      }
      if (
        this.price_rules.some(
          (pr) => !pr.min_threshold && pr.min_threshold !== 0
        )
      ) {
        return "Min Threshold must have a value";
      }
      const threshold_list = this.price_rules.map((pr) => pr.min_threshold);
      if (new Set(threshold_list).size !== threshold_list.length) {
        return "Cannot Update Price Rules with duplicate Thresholds";
      }
      if (
        this.price_rules.some(
          (pr) => !pr.factor || 0 > pr.factor || pr.factor > 100
        )
      ) {
        return "Factors must be a number between 0 - 100";
      }
      return null;
    },
    payload() {
      // group prices rules to by CRUD action type
      const payload = ACTIONS.reduce((acc, act) => ({ ...acc, [act]: [] }), {
        type_: PRICE_RULE_TYPE_DEFAULT,
        target: this.activeTarget,
      });
      this.price_rules.forEach((pr) => {
        if (pr.action_type) {
          payload[pr.action_type].push(pr);
        }
      });
      return payload;
    },
  },
  methods: {
    ...mapActions("price_rule", ["getDefaultPriceRules", "createPriceRules"]),
    ...mapActions("product", ["updatePriceRules"]),
    getThresholdRange(price_rule_index) {
      const current_price_rule = this.price_rules[price_rule_index];
      const next_price_rule = this.price_rules[price_rule_index + 1];
      if (
        current_price_rule.min_threshold === null ||
        typeof current_price_rule.min_threshold === "undefined"
      )
        return "Set Min Threshold";
      if (next_price_rule && next_price_rule.min_threshold) {
        if (next_price_rule.min_threshold < current_price_rule.min_threshold)
          return SORT_THRESHOLDS;
        const next_threshold_string =
          next_price_rule.min_threshold / 100
            ? `$${(next_price_rule.min_threshold / 100 - 0.01).toFixed(2)}`
            : "";
        return `$${(current_price_rule.min_threshold / 100).toFixed(
          2
        )} - ${next_threshold_string}`;
      } else {
        return `$${(current_price_rule.min_threshold / 100).toFixed(2)} +`;
      }
    },
    addNewPriceRule() {
      const new_price_rule = {
        ...PRICE_RULE_TEMP,
        action_type: ACTION_TYPE_CREATE,
        id: Math.floor(Math.random() * 1000000000),
      };
      if (!this.price_rules.length) {
        new_price_rule.min_threshold = 0;
      }
      this.price_rules = [...this.price_rules, new_price_rule];
    },
    findLocalPriceRule(price_rule_id) {
      const price_rule_index = this.price_rules.findIndex(
        (pr) => pr.id === price_rule_id
      );
      const price_rule = this.price_rules.find((pr) => pr.id === price_rule_id);
      return { price_rule, price_rule_index, price_rule_id };
    },
    handleThresholdChange(threshold, price_rule_id) {
      const { price_rule, price_rule_index } = this.findLocalPriceRule(
        price_rule_id
      );
      console.log("received", threshold);
      if (threshold === "0") return;
      price_rule.min_threshold = Number(threshold);
      if (!price_rule.action_type) {
        price_rule.action_type = ACTION_TYPE_UPDATE;
      }
      this.$set(this.price_rules, price_rule_index, price_rule);
    },
    handleFactorChange(factor, price_rule_id) {
      const { price_rule, price_rule_index } = this.findLocalPriceRule(
        price_rule_id
      );
      price_rule.factor = Number(factor);
      if (!price_rule.action_type) {
        price_rule.action_type = ACTION_TYPE_UPDATE;
      }
      this.$set(this.price_rules, price_rule_index, price_rule);
    },
    sortPriceRules() {
      this.price_rules = this.price_rules.sort((a, b) => {
        return a.min_threshold - b.min_threshold;
      });
    },
    handleDelete(price_rule_id) {
      const { price_rule, price_rule_index } = this.findLocalPriceRule(
        price_rule_id
      );
      if (
        price_rule.action_type &&
        price_rule.action_type === ACTION_TYPE_CREATE
      ) {
        this.$delete(this.price_rules, price_rule_index);
      } else {
        this.$set(this.price_rules, price_rule_index, {
          ...price_rule,
          action_type: ACTION_TYPE_DELETE,
        });
      }
    },
    clearMessages() {
      setTimeout(() => {
        this.error = this.success = this.changes = "";
      }, 2000);
    },
    dispatchPriceRuleChanges() {
      this.changes = "";
      this.createPriceRules(this.payload)
        .then(() => {
          this.success = "Updated Successfully";
          this.clearMessages();
        })
        .catch(() => {
          this.error = "Could Not Update Price Rules";
          this.clearMessages();
        });
    },
    sendUpdatePriceRules() {
      if (
        !confirm(
          "Are you sure you want to update price rules again? This will loop through all products and may take several hours do not double click!"
        )
      )
        return;
      this.updatePriceRules(this.activeTarget);
    },
    updateLocalPriceRules() {
      if (!this.activeTarget) {
        this.activeTarget = this.targetOptions[0].id;
      }
      console.log(this.priceRulesDefaultByTarget(this.activeTarget));
      this.price_rules = this.priceRulesDefaultByTarget(this.activeTarget);
    },
  },
  watch: {
    activeTarget: {
      handler() {
        this.updateLocalPriceRules();
      },
    },
    priceRulesDefault: {
      handler() {
        this.updateLocalPriceRules();
      },
    },
    payload: {
      handler() {
        this.changes = [
          ...this.payload[ACTION_TYPE_DELETE],
          ...this.payload[ACTION_TYPE_CREATE],
          ...this.payload[ACTION_TYPE_UPDATE],
        ].length
          ? "Unsaved Changes"
          : "";
      },
      deep: true,
    },
  },
};
</script>
