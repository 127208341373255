
<template lang="html">
    <div class="flex flex-col items-start relative">
    <label v-if="label" class="block text-gray-700 text-sm font-bold mb-2" for="password">
       {{label}}
    </label>
    <input
        :id="id"
        :type="type"
        tabindex="0"
        @input="handleInput"
        :value="value"
        :placeholder="placeholder"
        :min="0"
        :step="step"
        :disabled="disabled"
        :class="cls"
        :autocomplete="autocomplete"
        class=" shadow appearance-none border border-gray-700 rounded w-full py-2 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
    />
     <p class="absolute text-red-500 text-xs italic bottom-0" v-if="error">{{error}}</p>
     </div>
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        error: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        autocomplete: {
            type: String,
        },
        type: {
            type: String,
        },
        step: {
            type: Number,
        },
    },
    data() {
        return {
            key: `input_${this.id}`,
        }
    },
    computed: {
        cls() {
            return { 'border-red-500': this.error && this.error.length }
        },
    },
    methods: {
        handleInput(e) {
            this.$nextTick(() => {
                this.$emit('input', e.target.value)
            })
        },
    },
}
</script>
