<template>
    <div class="flex flex-col items-start relative">
        <label
            v-if="label"
            class="block text-gray-700 text-sm font-bold mb-2"
            :for="id"
        >
            {{ label }}
        </label>

        <div class="inline-block relative w-full">
            <select
                :value="value"
                :disabled="disabled"
                @input="selected"
                class="shadow appearance-none border border-gray-700 rounded w-full py-2 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
            >
                <!-- select without headers -->
                <option
                    :value="option.id || option.value"
                    v-for="(option, index) in options"
                    :key="`${index}-${option.id || option.value}`"
                >
                    {{ option.name }}
                </option>
            </select>
            <div
                class="pointer-events-none absolute top-0 right-0 flex items-center px-2 mt-3"
            >
                <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                </svg>
            </div>
        </div>
        <p class="absolute text-red-500 text-xs italic bottom-0" v-if="error">
            {{ error }}
        </p>
    </div>
</template>
<script>
import BaseInput from './BaseInput.vue'
// import BaseSVG from './BaseSVG.vue'
import { mapActions } from 'vuex'
export default {
    components: { BaseInput },
    name: 'BaseSelect',
    props: {
        value: String,
        options: Array,
        disabled: Boolean,
        label: String,
        error: String,
        id: String,
    },

    methods: {
        selected(e) {
            this.$emit('input', e.target.value)
        },
    },
}
</script>
