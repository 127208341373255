<template>
  <form @submit.prevent>
    <slot></slot>
  </form>
</template>

<script>
export default {
    name: 'BaseForm',
    data() {
        return {
            state: {},
        }
    },
}
</script>

