<script>
import { CurrencyInput } from 'vue-currency-input'
export default {
    name: 'BaseCurrency',
    components: {
        CurrencyInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        display: {
            type: Boolean,
        },
        error: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        autocomplete: {
            type: String,
        },
    },
    data() {
        return {
            key: `input_${this.id}`,
        }
    },
    computed: {
        cls() {
            return {
                'border-red-500': this.error && this.error.length,
                'border-none': this.display,
            }
        },
        disabledLocal() {
            return this.display ? true : this.disabled
        },
    },
    methods: {
        handleInput(e) {
            // grab the value from the input
            this.$nextTick(() => {
                this.$emit('input', e * 100)
            })
        },
    },
}
</script>

<template lang="html">
    <div class="flex flex-col items-start relative">
        <label
            v-if="label"
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password"
        >
            {{ label }}
        </label>
        <currency-input
            currency="USD"
            locale="en"
            :id="id"
            tabindex="0"
            @input="handleInput"
            :value="value / 100"
            :placeholder="placeholder"
            :min="0"
            :disabled="disabledLocal"
            :class="cls"
            :autocomplete="autocomplete"
            class="bg-white shadow appearance-none border border-gray-700 rounded w-full py-2 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
        />
        <p class="absolute text-red-500 text-xs italic bottom-0" v-if="error">
            {{ error }}
        </p>
    </div>
</template>
