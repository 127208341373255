var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col justify-evenly"},[_c('div',{staticClass:"w-full flex items-start flex-col"},[_c('div',{staticClass:"flex justify-start w-full text-xl mb-2"},[_vm._v(" Setting Pricing ")]),_c('div',{staticClass:"flex flex-start w-full"},[_c('BaseCurrency',{staticClass:"w-40",attrs:{"value":_vm.metalPriceLast.gold * 100,"type":"number","label":"Last Gold Price / oz","id":"last-gold-price","display":true}}),_c('BaseCurrency',{staticClass:"ml-3 w-40",attrs:{"value":_vm.metalPrice.gold * 100,"type":"number","label":"Current Gold / oz","id":"current-gold-price"},on:{"input":function($event){_vm.metalPrice.gold = $event / 100}}}),_c('div',{staticClass:"ml-3 mt-8 pt-1 text-md whitespace-nowrap w-20",class:{ 'text-red-700': _vm.getPriceDiff('gold') < 0 }},[_vm._v(" "+_vm._s(_vm.getPriceDiff("gold"))+" % ")]),_c('BaseCurrency',{staticClass:"ml-3 w-40",attrs:{"value":_vm.convert(
              _vm.convert(_vm.metalPrice.gold * 100, 'ounce', 'dwt'),
              'gold',
              '18k'
            ),"type":"number","display":true,"label":"USD/18k gold dwt ","id":"current-gold-price"}}),_c('BaseCurrency',{staticClass:"ml-3 w-40",attrs:{"value":_vm.convert(
              _vm.convert(_vm.metalPrice.gold * 100, 'ounce', 'dwt'),
              'gold',
              '14k'
            ),"type":"number","label":"USD / 14k gold dwt ","id":"current-gold-price","display":true}})],1),_c('div',{staticClass:"flex flex-start w-full"},[_c('BaseCurrency',{staticClass:"w-40",attrs:{"value":_vm.metalPriceLast.platinum * 100,"type":"number","label":"Last Platinum / oz","id":"last-platinum-price","display":true}}),_c('BaseCurrency',{staticClass:"ml-3 w-40",attrs:{"value":_vm.metalPrice.platinum * 100,"type":"number","label":"Current Platinum / oz","id":"current-platinum-price"},on:{"input":function($event){_vm.metalPrice.platinum = $event / 100}}}),_c('div',{staticClass:"ml-3 mt-8 pt-1 text-md whitespace-nowrap w-20",class:{ 'text-red-700': _vm.getPriceDiff('platinum') < 0 }},[_vm._v(" "+_vm._s(_vm.getPriceDiff("platinum"))+" % ")]),_c('BaseCurrency',{staticClass:"ml-3 w-40",attrs:{"value":_vm.convert(_vm.metalPrice.platinum * 100, 'ounce', 'dwt'),"type":"number","display":true,"label":"USD/pure platinum dwt ","id":"current-platinum-price"}})],1)]),_c('div',{staticClass:"mt-8 underline italic cursor-pointer flex flex-end w-full"},[_c('div',{on:{"click":_vm.sendUpdatePricingRequest}},[_vm._v("Update Product Pricing")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }